"use strict";
require( "./../env.js" );

const Sentry = require( "sentry" );
const { getBearerToken } = require( "users/firebase/getBearerToken" );
const { setupAuthTokenCallback } = require( "./common/replaceGlobalFetch" );
setupAuthTokenCallback( getBearerToken );

if ( typeof Raven === "undefined" ) {
    /* eslint-disable no-undef */
    global.Raven = {};
    Raven.context = function( callback ) {
        callback();
    };
    // eslint-disable-next-line no-console
    Raven.captureException = ( err ) => { console.error( err ); };
}

require( "../../public_html/source/application/prototype.js" );
require( "../../public_html/source/objects/object.js" );
require( "../../public_html/source/objects/array.js" );
require( "elements" );

window.APP = {};
import { setupServiceWorker } from "serviceWorker";

window.PAGES_ACCESS = [];

/**
 * Run a fetch from the server to load the resources
 * 
 * @param {String} url 
 */
async function loadResources( url ) {

    let result = await fetch( url );
    result = await result.json();

    return result;
}

/**
 * Save some of the resources to the local Storage
 * 
 * @param {Object} json 
 * 
 * @returns {Promise}
 */
function saveResources( json ) {
    return new Promise( ( resolve ) => {
        setTimeout( () => {
            try {
                // get the types to ignore saving to localStorage to save space
                let types = json.types;
                types.push( "js" );

                // create the new save object
                let save = {};

                for ( let c in json ) {
                    if ( types.includes( c ) ) {
                        continue;
                    }
                    save[ c ] = json[ c ];
                }
                // try to save the resources
                localStorage.resources = JSON.stringify( save );
            } catch ( err ) {
                Sentry.withScope( scope => {
                    scope.setExtra( "msg", "Failed to save Resources on App load" );

                    Sentry.captureException( err );
                } );
            }
            resolve();
        }, 5 );
    } );
}


$( document ).ready( () => {
    // eslint-disable-next-line no-console
    console.log( "%cLoad Resources", "color:grey;" );

    let run = async () => {
        try {

            // let data = await loadResources( `/api/resources/?version=${VERSION}&template=none` ); // ${CURRENT_APP_TEMPLATE}

            window.addEventListener( "online", APP.isoffline );
            window.addEventListener( "offline", APP.isoffline );

            // saveResources( data ); // save the resources from server

        } catch ( err ) {
            // eslint-disable-next-line no-undef
            Sentry.captureException( err );
            // eslint-disable-next-line no-console
            console.error( err );
        }
    };

    run();

} );

// load the application resources
( () => {

    let appLoaded = false;

    if ( 'serviceWorker' in navigator ) {
        ( async () => {
            setState( "Registering services" );
            // register the service worker
            try {
                const wb = await setupServiceWorker();

                // check if the user is logged in. 
                // Since the service worker is registered already I can call a url and the service worker should add the auth token
                setState( "Logging In" );
                try {
                    const result = await ( await fetch( "/login/access/pages" ) ).json();
                    Sentry.addBreadcrumb( "User Logged In", result.access );

                    if ( result.access ) {

                        setState( "Loading App" );
                        setUser( result.user );

                        // check if the user has access to the current page
                        const pathName = location.pathname.length > 1 ? location.pathname.replace( /\/$/, "" ) : location.pathname;

                        window.PAGES_ACCESS = result.pages;

                        if ( result.pages.includes( pathName ) ) {
                            // continue opening application... The user has access to open the page
                            if ( !appLoaded ) {
                                window.AppHasLoaded = true;
                                await loadMainApplication();
                                appLoaded = true;
                            }
                        } else {
                            // redirect the user to the first page available
                            const page = result.pages[ 0 ];
                            Sentry.addBreadcrumb( "Redirecting to > " + page );

                            location.assign( page );
                        }

                    } else {

                        // the user isn't logged in... Send to login page
                        console.log( "Redirect to Login Page" );
                        location.assign( `/login?redirect=${encodeURIComponent(location.href)}` );
                    }
                } catch ( err ) {
                    // the application failed to load... This current path should be cached in the service worker so if it's not cached assume the user isn't logged in
                    location.assign( `/login?redirect=${encodeURIComponent(location.href)}` );
                }

            } catch ( err ) {
                // something went wrong while setting up application
                console.error( 'ServiceWorker registration failed: ', err );

                Sentry.withScope( scope => {
                    scope.setTag( "ServiceWorkerRegistration", "Failed" );
                    scope.setTag( "CURRENT_APP_TEMPLATE", CURRENT_APP_TEMPLATE );
                    scope.setExtra( "msg", "Failed to register the service worker" );
                    Sentry.captureException( err );
                } );
            }
        } )();

        // navigator.serviceWorker.addEventListener( 'message', event => {
        //     try {

        //         switch ( event.data.event ) {

        //             // the service worker was updated
        //             case "update":
        //                 const lastUpdate = localStorage.lastUpdate ? JSON.parse( localStorage.lastUpdate ) : {};

        //                 if ( lastUpdate.version !== event.data.version ) {
        //                     if ( appLoaded ) {
        //                         APP.notifier.add( "a", "An update is available.", 0, "Dismiss", "Refresh", () => {}, () => location.reload(
        //                             true ) );
        //                     } else {
        //                         location.reload();
        //                     }
        //                 }

        //                 localStorage.lastUpdate = JSON.stringify( { version: event.data.version, time: Date.now() / 1000 } );

        //                 break;

        //             case "downloading-update":
        //                 // a new update is being installed
        //                 setState( "Downloading Update" );
        //                 break;
        //         }

        //     } catch ( err ) {}
        // } );
    } else {
        setError( "Could not register services." );
    }

} )();

/**
 * Load the script from url
 * 
 * @param {string} url 
 */
function loadScript( url ) {
    return new Promise( ( resolve, reject ) => {
        const script = document.createElement( "script" );
        script.type = "text/javascript";

        if ( script.readyState ) { // only required for IE <9
            script.onreadystatechange = function() {
                if ( script.readyState === "loaded" || script.readyState === "complete" ) {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else { //Others
            script.onload = resolve;
        }

        script.onError = reject;
        script.addEventListener( "error", reject );
        script.addEventListener( "load", resolve );

        script.src = url;
        document.getElementsByTagName( "head" )[ 0 ].appendChild( script );
    } );
}

/**
 * Load the main application script
 */
async function loadMainApplication( retryCount ) {
    retryCount = typeof retryCount === "number" ? retryCount : 1;
    let template = CURRENT_APP_TEMPLATE;

    if ( template === "worker" ) {
        template = "workorders";
    }

    try {

        await loadScript( `/dist/${template}.js` );
        // await loadScript( "/api/resources/sourceFiles.php?f=main&template=" + CURRENT_APP_TEMPLATE );
        console.log( "%cApp Loaded", "color:green;" );

    } catch ( err ) {
        // script failed to load for some reason

        if ( retryCount ) {
            // ignore the error and try again if fetch is successful
            retryCount--;
            try {

                // try to load the resources with a fetch function
                await retryLoadingResources( `/dist/${template}.js` );

                // try loading one more time
                await loadMainApplication( retryCount );

            } catch ( e ) {
                if ( e.status === 403 ) {
                    // send the user to re-login
                    location.assign( "/login" );
                }
            }
        }

        console.error( err );
        let sentryId = null;

        Sentry.withScope( scope => {
            scope.setExtra( "msg", "Failed to load main script on application start." );

            if ( !( err instanceof Error ) ) {
                scope.setExtra( "event", err );
            }

            scope.setLevel( "fatal" );

            if ( err instanceof Error ) {
                sentryId = Sentry.captureException( err );
            } else {
                sentryId = Sentry.captureException( new Error( "Failed to load main script on application start." ) );
            }
        } );

        setError( "Failed to load the main application.", sentryId );
    }
}

/**
 * Set the current application state
 * 
 * @param {string} msg 
 */
function setState( msg ) {
    try {
        document.getElementById( "loading-state" ).innerText = msg;
    } catch ( err ) {
        // ignore error. App was probably loaded already and this element deleted
    }
}

/**
 * Tries to load the resource file with fetch to get the correct error code
 * 
 * @param {string} url
 */
async function retryLoadingResources( url ) {

    const response = await fetch( url );
    if ( response && response.status === 200 ) {
        return null;
    }
    const err = new Error( "Failed to load script file." );
    err.status = response.status;
    throw err;
}


/**
 * Set the error message to show while loading the app
 * 
 * @param {*} msg 
 * @param {*} errorId 
 */
function setError( msg, errorId ) {
    const el = document.getElementById( "ErrorMsg" );

    el.innerText = msg;
    el.style.display = "inline-block";

    if ( errorId ) {
        el.createChild( "a", {
            classList: [ "btn", "btn-warning", "d-inline-flex", "m-2" ],
            childNodes: [ {
                type: "i",
                options: {
                    classList: [ "material-icons" ],
                    text: "warning"
                }
            }, {
                child: document.createTextNode( "Report Error" ),
            } ],
            on: {
                click: () => {
                    Sentry.showReportDialog( { eventId: errorId } );
                }
            }
        } );
    }
}

/**
 * Welcome the user back online
 * 
 * @param {*} user 
 */
function setUser( user ) {
    document.getElementById( "logging-in-user" ).innerHTML = `<i class="material-icons">account_circle</i>Hi ${user.given_name}!`;

    Sentry.setUser( {
        email: user.email,
        username: user.name,
    } );
}