var object;
( function() {
    window.object = {
        foreach: function( objects, func ) {
            var ret = [];
            for ( var key in objects ) {
                // skip loop if the property is from prototype
                if ( !objects.hasOwnProperty( key ) ) continue;
                ret.push( func( objects, key ) );
            }
            return ret;
        },
        isEqual: function( obj1, obj2 ) {
            if ( typeof obj1 !== typeof obj2 ) {
                return false;
            }
            return getArrayValue( compare( obj1, obj2 ) );

            function compare( obj1, obj2 ) {
                if ( obj1.length !== obj2.length ) {
                    return false;
                }
                return object.foreach( obj1, function( obj1, key1 ) {
                    return object.foreach( obj2, function( obj2, key2 ) {
                        if ( typeof obj1[ key2 ] === "undefined" ) {
                            return false;
                        }
                        if ( typeof obj2[ key1 ] === "undefined" ) {
                            return false;
                        }
                        if ( key1 === key2 ) {
                            if ( obj1[ key1 ].length !== obj2[ key1 ].length ) {
                                console.log( "Length " + obj1[ key1 ].length + " = " + obj2[ key2 ].length + " > " + key1 );
                                return false;
                            }
                            if ( obj1[ key2 ].length !== obj2[ key2 ].length ) {
                                return false;
                            }
                            if ( typeof obj1[ key1 ] === typeof obj2[ key1 ] ) {
                                if ( typeof obj1[ key1 ] === "object" ) {
                                    return compare( obj1[ key1 ], obj2[ key1 ] );
                                } else {
                                    return obj1[ key1 ] === obj2[ key1 ];
                                }
                            }
                            return undefined;
                        }
                        return undefined;
                    } );
                } );

            }

            function getArrayValue( arr ) {
                if ( typeof arr === "boolean" ) {
                    return arr;
                }
                for ( var i = 0; i < arr.length; i++ ) {
                    if ( typeof arr[ i ] === "object" ) {
                        if ( !getArrayValue( arr[ i ] ) ) {
                            return false;
                        }
                    }
                    if ( typeof arr[ i ] === undefined ) continue;
                    if ( arr[ i ] === false ) {
                        return false;
                    }
                }
                return true;
            }
        },
        isSame: function( obj1, obj2 ) {
            if ( typeof obj1 !== typeof obj2 ) {
                return false;
            }
            try {
                var json1 = JSON.stringify( obj1 );
                var json2 = JSON.stringify( obj2 );
                if ( json1 === json2 ) {
                    return true;
                }
            } catch ( er ) {}
            return getArrayValue( compare( obj1, obj2 ) );

            function compare( obj1, obj2 ) {
                if ( obj1.length !== obj2.length ) {
                    return false;
                }
                return object.foreach( obj1, function( obj1, key1 ) {
                    return object.foreach( obj2, function( obj2, key2 ) {
                        if ( typeof obj1[ key2 ] === "undefined" ) {
                            return false;
                        }
                        if ( typeof obj2[ key1 ] === "undefined" ) {
                            return false;
                        }
                        if ( key1 === key2 ) {
                            if ( typeof obj1[ key1 ] === "number" ) {
                                obj1[ key1 ] = obj1[ key1 ].toString();
                            }
                            if ( typeof obj2[ key2 ] === "number" ) {
                                obj2[ key2 ] = obj2[ key2 ].toString();
                            }
                            if ( obj1[ key1 ].length !== obj2[ key1 ].length ) {
                                return false;
                            }
                            if ( obj1[ key2 ].length !== obj2[ key2 ].length ) {
                                return false;
                            }
                            if ( typeof obj1[ key1 ] === typeof obj2[ key1 ] ) {
                                if ( typeof obj1[ key1 ] === "object" ) {
                                    return compare( obj1[ key1 ], obj2[ key1 ] );
                                } else {
                                    return obj1[ key1 ] === obj2[ key1 ];
                                }
                            }
                            return undefined;
                        }
                        return undefined;
                    } );
                } );

            }

            function getArrayValue( arr ) {
                if ( typeof arr === "boolean" ) {
                    return arr;
                }
                for ( var i = 0; i < arr.length; i++ ) {
                    if ( typeof arr[ i ] === "object" ) {
                        if ( !getArrayValue( arr[ i ] ) ) {
                            return false;
                        }
                    }
                    if ( typeof arr[ i ] === undefined ) continue;
                    if ( arr[ i ] === false ) {
                        return false;
                    }
                }
                return true;
            }
        },
        sort: function( list ) {
            return APP.sort.sort( list );
        },
        reverse: function( list ) { // Reverses the order of the Array
            if ( list && Array.isArray( list ) && list.length ) {
                var arr = [];
                while ( list.length > 0 ) {
                    arr.push( list.pop() );
                }
                return arr;
            } else {
                var er = new Error( "property length of array not found" );
                error.log( er, "property length of array not found" );
            }
            return false;
        }
    };
} )();