/**ADD ALL SPECIAL PROTOTYPE FUNCTIONS**/
( function() {
    String.prototype.escapeSpecialChars = function() {
        return this.replace( /\n/g, "\\n" )
            .replace( /\'/g, "\\'" )
            .replace( /\"/g, '\\"' )
            .replace( /\&/g, "\\&" )
            .replace( /\r/g, "\\r" )
            .replace( /\t/g, "\\t" )
            .replace( /\f/g, "\\f" );
    };
    String.prototype.toNumber = function() {
        return +this.replace( /[^\d.-]/g, "" );
    };
    String.prototype.toPrice = function( character ) {
        return this.toNumber().toPrice( character );
    };
    String.prototype.add = function( val ) {
        return this.toNumber().add( val );
    };
    String.prototype.subtract = function( val ) {
        return this.toNumber().add( val );
    };
    String.prototype.minus = function( val ) {
        return this.subtract( val );
    };
    String.prototype.multiply = function( val ) {
        return this.toNumber().add( val );
    };
    String.prototype.times = function( val ) {
        return this.multiply( val );
    };
    String.prototype.comma = function( val ) {
        return this.toNumber().add( val );
    };
    String.prototype.percentIncrease = function( val ) {
        return this.toNumber().percentIncrease( val );
    };
    String.prototype.percentDecrease = function( val ) {
        return this.toNumber().percentDecrease( val );
    };
    String.prototype.replaceAll = function( strReplace, strWith, ignoreCase ) {
        ignoreCase = typeof ignoreCase === "boolean" ? ignoreCase : false;
        var esc = strReplace.replace( /[-\/\\^$*+?.()|[\]{}]/g, '\\$&' );
        var reg;
        if ( ignoreCase ) {
            reg = new RegExp( esc, 'ig' );
        } else {
            reg = new RegExp( esc, 'g' );
        }
        return this.replace( reg, strWith );
    };
    /**
     * @see http://stackoverflow.com/q/7616461/940217
     * @return {number}
     */
    String.prototype.hashCode = function() {
        if ( Array.prototype.reduce ) {
            //return this.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
        }
        var hash = 0;
        if ( this.length === 0 ) return hash;
        for ( var i = 0; i < this.length; i++ ) {
            var character = this.charCodeAt( i );
            hash = ( ( hash << 5 ) - hash ) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    };

    if ( !String.prototype.startsWith ) {
        String.prototype.startsWith = function( search, pos ) {
            return this.substr( !pos || pos < 0 ? 0 : +pos, search.length ) === search;
        };
    }


    Number.prototype.toNumber = function() {
        return +this;
    };
    Number.prototype.toPrice = function( character ) {
        character = typeof character !== "undefined" ? character : "";

        var fl = parseFloat( Math.round( this * 100 ) / 100 ).toFixed( 2 );
        return character + fl.replace( /\B(?=(\d{3})+(?!\d))/g, "," );
    };
    Number.prototype.length = function() {
        return this.toString().length;
    };
    Number.prototype.add = function( val ) {
        if ( typeof val === "undefined" ) {
            return this;
        }
        if ( typeof val == "number" ) {
            return this + val;
        }
        if ( typeof val == "string" ) {
            return this + val.toNumber();
        }
        if ( typeof val == "object" ) {
            var num = this;
            for ( var i = 0; i < val.length; i++ ) {
                num += val[ 0 ].toNumber();
            }
            return num;
        }
        return NaN;
    };
    Number.prototype.subtract = function( val ) {
        if ( typeof val === "undefined" ) {
            return this;
        }
        if ( typeof val == "number" ) {
            return this - val;
        }
        if ( typeof val == "string" ) {
            return this - val.toNumber();
        }
        if ( typeof val == "object" ) {
            var num = this;
            for ( var i = 0; i < val.length; i++ ) {
                num = num - val[ 0 ].toNumber();
            }
            return num;
        }
        return NaN;
    };
    Number.prototype.minus = function( val ) {
        return this.subtract( val );
    };
    Number.prototype.multiply = function( val ) {
        if ( typeof val === "undefined" ) {
            return this;
        }
        if ( typeof val == "number" ) {
            return this * val;
        }
        if ( typeof val == "string" ) {
            return this * val.toNumber();
        }
        if ( typeof val == "object" ) {
            var num = this;
            for ( var i = 0; i < val.length; i++ ) {
                num = num * val[ i ].toNumber();
            }
            return num;
        }
        return NaN;
    };
    Number.prototype.times = function( val ) {
        return this.multiply( val );
    };
    Number.prototype.comma = function() {
        return this.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
    };
    Number.prototype.percentIncrease = function( val ) {
        val = typeof val !== "undefined" ? val : 0;
        var increase = val.toNumber() - this;
        return increase / this * 100;
    };
    Number.prototype.percentDecrease = function( val ) {
        val = typeof val !== "undefined" ? val : 0;
        var decrease = this - val.toNumber();
        return decrease / this * 100;
    };
} )();