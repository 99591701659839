import { Workbox, messageSW } from 'workbox-window/Workbox.mjs';
import * as Sentry from "sentry";
let activatedCallback = () => {};

/**
 * Show a notification to the user
 * 
 * @param {(msg) => void} callback 
 */
export function setupActivatedCallback( callback ) {
    activatedCallback = callback;
}

/**
 * Setup the service worker
 * 
 * @returns {Workbox}
 */
export async function setupServiceWorker() {
    let registration = null;
    const wb = new Workbox( "/service-worker.js", {
        scope: "/",
    } );

    wb.addEventListener( "installed", ( event ) => {
        Sentry.addBreadcrumb( {
            category: "work-box-event",
            message: "installed",
            data: {
                isUpdate: event.isUpdate,
            },
        } );

    } );

    wb.addEventListener( "activated", ( event ) => {
        Sentry.addBreadcrumb( {
            category: "work-box-event",
            message: "activated",
            data: {
                isUpdate: event.isUpdate,
            },
        } );

        if ( event.isUpdate ) {
            const lastUpdate = localStorage.lastUpdate ? JSON.parse( localStorage.lastUpdate ) : {};


            // the service worker was updated
            try {
                if ( lastUpdate.version !== VERSION ) {

                    let message = "A new update is available.";

                    // check if it's a critical update
                    if ( lastUpdate.version && lastUpdate.version.split( "." )[ 1 ] !== VERSION.split( "." )[ 1 ] ) {
                        message = "A new update is available. Please reload before doing any important work.";
                    }

                    APP.notifier.add( "a", message, null, "Dismiss", "Reload", () => {}, () => {
                        location.reload();
                    } );
                    localStorage.lastUpdate = JSON.stringify( {
                        version: VERSION,
                        time: Date.now() / 1000,
                    } );
                } else {
                    console.log( "%cUpdate event without popup", "color:red;font-weight:bold;" );
                }
            } catch ( err ) {
                // probably in the login page
            }
        }

        activatedCallback();
    } );

    wb.addEventListener( 'waiting', ( event ) => {

        Sentry.addBreadcrumb( {
            category: "work-box-event",
            message: "waiting",
            data: {
                isUpdate: event.isUpdate,
            },
        } );

    } );
    // cSpell: disable-next-line
    wb.addEventListener( 'externalwaiting', ( event ) => {

        Sentry.addBreadcrumb( {
            category: "work-box-event",
            message: "external-waiting",
            data: {
                isUpdate: event.isUpdate,
            },
        } );

    } );

    wb.addEventListener( "controlling", ( event ) => {
        Sentry.addBreadcrumb( {
            category: "work-box-event",
            message: "controlling",
            data: {
                isUpdate: event.isUpdate,
            },
        } );
    } );

    registration = wb.register();
    return wb;
}