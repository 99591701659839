if ( typeof HTMLElement.prototype.createChild === "undefined" ) {

    let optionBlacklist = [ "html" ];
    let pluginList = [];

    /**
     * @name HTML.createChild();
     * @param {string} type Element type to create
     * @param {object} options Options for the element
     * @param {htmlNode} node A sibling node to place before or after [options.insert = "before"]
     * @example document.createChild("div", {classList:["a","b"], id: "123", childNodes: [{type: "div", options:{insert:"before||after"}, node: div}]});
     * @example document.createChild("div", {childNodes: [{child: htmlElement}]});
     * @example document.createChild("div", {on: {click: function}});
     * @example document.createChild("div");
     */
    HTMLElement.prototype.createChild = function( type, options, node ) {
        if ( typeof type === "undefined" ) {
            return false;
        }
        var el;
        if ( typeof type !== "string" && isElement( type ) || isNode( type ) ) {
            el = type;
        } else {
            el = document.createElement( type );
        }
        options = typeof options === "object" ? options : {};
        if ( typeof options.classList === "object" ) {
            for ( var i = 0; i < options.classList.length; i++ ) {
                if ( options.classList[ i ] === "" ) {
                    console.warn( "Empty value given for classList" );
                    continue;
                }
                el.classList.add( options.classList[ i ] );
            }
        }
        // append html
        if ( typeof options.html === "string" ) {
            el.innerHTML = options.html;
        } else if ( typeof options.html === "object" && isElement( options.html ) ) {
            el.appendChild( options.html );
        }

        if ( typeof options.text === "string" || typeof options.text === "number" ) {
            el.appendChild( document.createTextNode( options.text ) );
        } else if ( typeof options.text === "object" && isElement( options.text ) ) {
            el.appendChild( options.text );
        }

        // a list of values to ignore
        var ignore = [ "classList", "insert", "childNodes", "text", "on", ...optionBlacklist ];

        for ( var i in options ) {
            /*// skip the index if it's in the ignore list */
            if ( ignore.includes( i ) ) {
                continue;
            }

            // handle the read only case
            if ( i.toLowerCase() === "readonly" && typeof options[ i ] === "boolean" ) {
                if ( options[ i ] ) {
                    el.setAttribute( "readonly", true );
                }
                continue;
            }

            // set the attributes of the element
            if ( typeof options[ i ] === "string" || typeof options[ i ] === "number" || typeof options[ i ] === "boolean" ) {

                el.setAttribute( i, options[ i ] );

            } else if ( typeof options[ i ] === "object" && Array.isArray( options[ i ] ) ) {

                for ( var j = 0; j < options[ i ].length; j++ ) {
                    el.setAttribute( i, options[ i ][ j ] );
                }

            } else if ( typeof options[ i ] === "function" ) {
                el.addEventListener( i, options[ i ] );
            }
        }
        if ( typeof options.childNodes === "object" ) {
            for ( var i = 0; i < options.childNodes.length; i++ ) {
                if ( typeof options.childNodes[ i ].child === "object" && ( isElement( options.childNodes[ i ].child ) || isNode( options.childNodes[ i ]
                        .child ) ) ) {
                    el.appendChild( options.childNodes[ i ].child );
                    continue;
                }
                el.createChild( options.childNodes[ i ].type, options.childNodes[ i ].options, options.childNodes[ i ].node );
            }
        }
        if ( typeof options.insert !== "string" ) {
            options.insert = "";
        }

        /**
         * Add the event listeners
         */
        if ( typeof options.on === "object" ) {
            for ( var f in options.on ) {
                if ( typeof options.on[ f ] === "function" ) {
                    el.addEventListener( f, options.on[ f ] );
                }
            }
        }

        /**
         * Run the plugins to change more data on the Element
         */
        for ( let i = 0; i < pluginList.length; i++ ) {
            try {

                pluginList[ i ]( el, options, node );

            } catch ( err ) {}
        }

        if ( options.insert === "before" && typeof node === "object" ) {
            this.insertBefore( el, node );
            return el;
        }
        if ( options.insert === "after" && typeof node === "object" && node.nextSibling ) {
            this.insertBefore( el, node.nextSibling );
            return el;
        }
        this.appendChild( el );
        return el;
    };
    document.createChild = function( type, options, node ) {
        var div = document.createElement( "div" );
        return div.createChild( type, options, node );
    };

    document.createChild.plugin = function( callback, blacklist ) {
        pluginList.push( callback );
        if ( Array.isArray( blacklist ) ) {
            optionBlacklist.push( ...blacklist );
        }
    };
    /**
     * Returns true if it is a DOM node
     * 
     * @name document.isNode
     * @param {HTMLElement} o the object to test if its a DOM Node
     * @return {boolean}
     * 
     * @example document.isNode(object)
     */
    function isNode( o ) {
        return (
            typeof Node === "object" ? o instanceof Node :
            o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
        );
    }
    document.isNode = isNode;

    /**
     * Returns true if it is a DOM element 
     * 
     * @name document.isElement
     * @param {HTMLElement} o the object to test if is a Html Element
     * @return {boolean}
     * 
     * @example document.isElement(object)
     */
    function isElement( o ) {
        return (
            typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
            o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
        );
    }
    document.isElement = isElement;
}