(function(){
	window.array = {
		sort:function(){
			
		},
		contains:function(object, string){
			if(object.indexOf(string) >= 0){
				return true;
			}
			return false;
		},
		remove: function(arr, args) {
			if(typeof args !== "object"){
				args = [args];
			}
			var what, a = args, L = a.length, ax;
			while (L && arr.length) {
				what = a[--L];
				while ((ax = arr.indexOf(what)) !== -1) {
					arr.splice(ax, 1);
				}
			}
			return arr;
		}
	};
})();